.App{
  height: 100vh;
  width: 100vw;
  font: normal normal normal 16px/1.4em futura-lt-w01-light,futura-lt-w05-light,sans-serif;
}

.header{
  display: flex;
  position: relative;
  z-index: 1;
  padding: 30px 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.01);
}

.logo{
  height: 80px;
}

.NavLink{
  text-decoration: none;
  color: black;
  padding: 15px 30px;
  transition: 0.5s;
}

.NavLink:hover{
  color: white;
  background-color: #A79982;
}

.active{
  color: white;
  background-color: #938265;
}

.home{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 60px);
  position: relative;
  background-color: #CDC4B4;
  padding: 30px;
}

.app-content{
  height: calc(100vh - 145.6px - 50px);
  position: relative;
}

.title{
  width: 75%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 10px;
  color: #333333;
}

.subtitle{
  width: 75%;
  color: #333333;
  text-align: center;
  font-size: 1.3rem;
  font-style: italic;
  font-family: "Trebuchet MS";
  font-weight: lighter;
  padding-bottom: 10px;
}

.text{
  width: 60%;
  font-size: 1rem;
  font-family: "Trebuchet MS";
  font-weight: lighter;
  padding-bottom: 10px;
}

.bold {
  font-weight: bold;
}

.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #333333;
  color: #DDDDDD;
}